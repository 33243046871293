import "./referralStyle.css";

function FranchiseData(props) {
  return (
    <div className="r-card">
      <div className="r-image">
        <img src={props.image} alt="image" />
      </div>
      <h4>{props.heading}</h4>
      <p> {props.text} </p>
      <a href={props.link}>{props.text1}</a>
    </div>
  );
}

export default FranchiseData;

import "./referralStyle.css";
import ReferralData from "./referralData";
import Owner1 from "../assets/r1.png";
import Owner2 from "../assets/missing.png";


function referral() {
  return (
    <div className="referral">
      <h1>Our Referral Program </h1>
      <p>
      Your Pathway to Success Starts Here
      </p>
      <div className="referralcard">
      <ReferralData image={Owner1} 
    heading="Sanjeev Nirmale"
      text="ID=TRMR0107202401" 
      text1="More Info"  
      link="/ReferralUsRef"/>

<ReferralData image={Owner2} 
      heading="Unkonwn"
      text="ID" 
      text1="More Info"  
      link=""/>

<ReferralData image={Owner2} 
      heading="Unkonwn"
      text="ID  " 
      text1="More Info"  
      link=""/>
      </div>
     

       
    </div>
  );
}

export default referral;

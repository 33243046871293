import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import logo from "../assets/r1.png";
import ReferralRef from "../component/ReferralRef";


function ReferralUsRef() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mids1"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Sanjeev Nirmale"
        text="ID=TRMR0106202401"
        image={logo}
      />
      <ReferralRef/>
      <Footer />
    </>
  );
}

export default ReferralUsRef;

import "./ImportanceUsStyle.css"
import photoo1 from "../assets/0001.jpg";
import phot2 from "../assets/0002.jpg";
import phot3 from "../assets/0003.jpg";
import phot4 from "../assets/0004.jpg";
import phot5 from "../assets/0005.jpg";
import phot6 from "../assets/0006.jpg";
import phot7 from "../assets/0007.jpg";
import phot8 from "../assets/0008.jpg";
import phot9 from "../assets/0009.jpg";
import phot10 from "../assets/0010.jpg";
import phot11 from "../assets/0011.jpg";
import phot12 from "../assets/0012.jpg";
import phot13 from "../assets/0013.jpg";
import phot14 from "../assets/0014.jpg";
import phot15 from "../assets/0015.jpg";
import phot16 from "../assets/0016.jpg";
import phot17 from "../assets/0017.jpg";
import phot18 from "../assets/0018.jpg";
import phot19 from "../assets/0019.jpg";
import phot20 from "../assets/0020.jpg";
import phot21 from "../assets/0021.jpg";
import phot22 from "../assets/0022.jpg";


function ImportanceUs() {
  
    return (
      <div className="ImportanceUss-Photo">
  
    
  <img src={photoo1} />
  
        <img src={phot2} />

        <img src={phot3} />
 
        <img src={phot4} />
     
        <img src={phot5} />
       
        <img src={phot6} />
       
        <img src={phot7} />
        
        <img src={phot8} />

    
        <img src={phot9} />
  
        <img src={phot10} />

        <img src={phot11} />
 
        <img src={phot12} />
     
        <img src={phot13} />
       
        <img src={phot14} />
       
        <img src={phot15} />
        
        <img src={phot16} />

        <img src={phot17} />
     
     <img src={phot18} />
    
     <img src={phot19} />
    
     <img src={phot20} />
     
     <img src={phot21} />

 
     <img src={phot22} />

    
        
        
      </div>
    );
  }
  export default ImportanceUs;
  
import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import GalleryPhoto from "../component/GalleryPhoto";
import logo from "../assets/GALL.jpeg";

function Gallery() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Photos & Video"
        image={logo}
      />
      <GalleryPhoto />
      <Footer />
    </>
  );
}
export default Gallery;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./NavbarStyles.css";
import { MenuItems } from "./Menultems";

class Navbar extends Component {
  state = {
    clicked: false,
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleMenuItemClick = (url) => {
    if (window.location.href === url) {
      return;
    } else {
      window.open(url, "_self");
    }
  };

  render() {
    return (
      <nav className="NavbarItems">
        <h1 className="navbar-logo">Top Rank Makers</h1>

        <div className="menu-icons" onClick={this.handleClick}>
          <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => (
            <li key={index}>
              <a
                className={item.cName}
                href={item.url}
                onClick={() => this.handleMenuItemClick(item.url)}
              >
                <i className={item.icon}></i>
                {item.title}
              </a>
            </li>
          ))}
          <li>
            <button
              className="nav-button"
              onClick={() => window.open("https://forms.gle/1WSHkXLTN2kngxBeA","_blank")}
            >
              Business Form
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;

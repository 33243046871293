import React from 'react';
import Footer from '../component/Footer';
import Hero from '../component/Hero';
import Navbar from '../component/Navbar';
import ServiceUs from '../component/ServiceUs';
import Franchise from '../component/Franchise';
import Referral from "../component/referral";
import photo08 from '../assets/a5.jpg';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head content

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mids"
        title={`TOP RANK MAKERS, Latur. Inviting you start your franchise Today..!`}
        text1="National Award by Maharashtra Governor - Resp. Rameshji Bais"
        text2="Are You Want to open Your Franchise"
        text={`    ✌🏻 First in History Online + Offline + ( Hybrid Model )   ✌🏻100% Profitable    ✌🏻Max Returns to your Investments
                         ✌🏻Zero Risk - Tension Free    ✌🏻Multi-window income powerful growth - Fast Earning    ✌🏻Name - Fame - Image Benefit
✌🏻Generation to Next Generation Business    ✌🏻Great social work, Unlimited Customers, Advance payments
    ✌🏻Artificial Intelligence (AI) Powered concept            ✌🏻Fast track Success...!                    ✌🏻  Lifetime support ! ! !`}
        buttonText2="Open Your Franchise"
        url2="https://forms.gle/qR5nfBqpSRVLprDGA"
        btnClass2="show2"
        image={photo08}
        buttonText="Our Franchise Owner"
        url="/franchise" // Updated to use route
        btnClass="show"
      />
      <ServiceUs />
      <Franchise />
      <Referral />
      <Footer />
    </>
  );
}

export default Home;

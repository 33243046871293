import "./FranchiseStyle.css";

function FranchiseData(props) {
  return (
    <a href={props.link} target="_blank" rel="noopener noreferrer" className="f-card-link">
    <div className="f-card">
      <div className="f-image">
        <img src={props.image} alt="image" />
      </div>
      <h4>{props.heading}</h4>
      <p>{props.text}</p>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.text1}
      </a>
    </div>
    </a>
  );
}

export default FranchiseData;

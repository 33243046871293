import "./ServiceUsStyle.css";
import phot1 from "../assets/s1.jpg";
import phot2 from "../assets/s2.jpg";
import phot3 from "../assets/s3.jpg";
import phot4 from "../assets/s4.jpg";
import phot5 from "../assets/s5.jpg";
import phot6 from "../assets/s6.jpg";
import phot7 from "../assets/s7.jpg";
import phot8 from "../assets/s8.jpg";
import phot9 from "../assets/s9.jpg";
import phot10 from "../assets/s10.jpg";
import phot11 from "../assets/s11.jpg";
import phot12 from "../assets/s12.jpg";
import phot13 from "../assets/s13.jpg";
import phot14 from "../assets/s14.jpg";
import phot15 from "../assets/s15.jpg";
import phot16 from "../assets/s16.jpg";
import phot17 from "../assets/s17.jpg";
import phot18 from "../assets/s18.jpg";
import phot19 from "../assets/s19.jpg";
import phot20 from "../assets/s20.jpg";
import phot21 from "../assets/s21.png";
import phot22 from "../assets/s22.png";
import phot23 from "../assets/s23.png";
import phot24 from "../assets/s24.jpg";



function ServiceUs() {
  
  return (
    <div className="ServiceUs-Photo">

       <a href="https://forms.gle/1WSHkXLTN2kngxBeA"  target="_blank"
            rel="noreferrer">
      <img src={phot1} />
      </a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot2} />
      </a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot3} />
     </a>
     <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot4} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot5} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot6} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot7} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot8} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot9} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot10} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot11} />
      </a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot12} />
     </a>
     <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot13} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot14} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot15} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot16} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot17} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot18} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot19} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot20} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
 <img src={phot21} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot22} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot23} /></a>
      <a href="https://forms.gle/1WSHkXLTN2kngxBeA" target="_blank"
            rel="noreferrer">
      <img src={phot24} /></a>
    </div>
  );
}
export default ServiceUs;

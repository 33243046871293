import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import ContactForm from "../component/ContactForm";
import logo from "../assets/toprank.jpg";

function Contact() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Contact"
        image={logo}
      />
      <ContactForm />
      <Footer />
    </>
  );
}

export default Contact;

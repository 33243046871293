import "./referralStyle.css";
import ReferralRefData from "./referralRefData";
import Owner1 from "../assets/MR-1.jpg";
import Owner2 from "../assets/missing.png";
import Owner3 from "../assets/missing.png";

function referralRef() {
  return (
    <div className="referralRef">
      <h1>Referral Persons</h1>
      <p>
      Making success stories of Others..!!
      </p>
      <div className="referralRefcard">
      <ReferralRefData image={Owner2} heading="Unkonwn"    />
      <ReferralRefData image={Owner2} heading="Unkonwn"    />
      <ReferralRefData image={Owner3} heading="Unkonwn"    />
      

      </div>
     

       
    </div>
  );
}

export default referralRef;
import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Topper from "../component/Topper";
import photo08 from "../assets/a5.jpg";

import Information from "../component/Information";

function Service() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        title="TOP RANK MAKERS, Latur"
        text="Reserve Your... ' Top Rank - Top Score
      - Top Result ' ( NEET & JEE )"
        buttonText="Admission Open"
        url="https://forms.gle/5zLk3HxF5NhkDKLm8"
        btnClass="show"
        text2="Are You Want to open Your Franchise"
        buttonText2="Open Your Franchise"
        url2="/"
        btnClass2="show2"
        image={photo08}
        text1="National Award by 
        Maharashtra Governer - Resp. Rameshji Bais"
      />
      <Information />
      <Topper />
      <Footer />
    </>
  );
}

export default Service;

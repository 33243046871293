import "./GalleryPhotoStyle.css";
import photo1 from "../assets/1.jpeg";
import photo2 from "../assets/2.jpeg";
import photo3 from "../assets/14.jpeg";
import photo4 from "../assets/4.jpeg";
import photo5 from "../assets/5.jpeg";
import photo6 from "../assets/13.jpeg";
import photo7 from "../assets/7.jpeg";
import photo8 from "../assets/8.jpeg";
import photo9 from "../assets/9.jpeg";
import photo10 from "../assets/10.jpeg";

import photo12 from "../assets/12.jpeg";
import photo13 from "../assets/15.jpeg";
import photo14 from "../assets/16.jpeg";
import photo15 from "../assets/17.jpeg";
import photo16 from "../assets/18.jpeg";
import photo17 from "../assets/19.jpeg";
import photo18 from "../assets/20.jpeg";
import photo01 from "../assets/a1.jpg";
import photo02 from "../assets/a2.jpg";
import photo03 from "../assets/a3.jpg";
import photo06 from "../assets/a6.jpg";
import photo05 from "../assets/a5.jpg";
import photo09 from "../assets/a9.jpg";
import photo010 from "../assets/a10.jpg";
import photo07 from "../assets/a7.jpg";
import photo08 from "../assets/a8.jpg";
import intovideo from "../assets/vio.mp4";
import rajvideo from "../assets/raj.mp4";
import missivideo from "../assets/missi.mp4";
import a1 from "../assets/aaa.mp4";
import a2 from "../assets/aaaa1.mp4";
import a3 from "../assets/aaaaa.mp4";
import ad4 from "../assets/ads1.mp4";
import ad5 from "../assets/ads2.mp4";
import tes1 from "../assets/tes1.mp4";
import tes2 from "../assets/tes2.mp4";
import tes3 from "../assets/tes3.mp4";
import tes4 from "../assets/tes4.mp4";
import tes5 from "../assets/tes5.mp4";
import tes6 from "../assets/tes6.mp4";

function GalleryPhoto() {
  return (
    <div className="Gallery-Photo">

      <img src={photo05} />
      <img src={photo08} />
      <img src={photo06} />
       <img src={photo01} />
       <img src={photo09} />
       <img src={photo010} />
      <img src={photo02} />
      <img src={photo03} />
      <img src={photo07} />
      <img src={photo1} />
      <img src={photo2} />
      <img src={photo4} />
      <img src={photo5} />
     
      <img src={photo6} />
      <img src={photo18} />
      <img src={photo7} />
      
      <img src={photo9} />
   
    
     
      <img src={photo13} />
     
      
      <img src={photo17} />
   
    
        <video
          src={intovideo}
        
          controls="controls"
        />
      


<video
          src={a1}
        
          controls="controls"
        />
        <video
          src={a2}
         
          controls="controls"
        />

<video
          src={a3}
        
          controls="controls"
        />
        <video
          src={ad4}
        
          controls="controls"
        />
      




<video
          src={tes1}
        
          controls="controls"
        />

<video
          src={tes2}
        
          controls="controls"
        />
        <video
          src={tes3}
         
          controls="controls"
        />

<video
          src={tes4}
        
          controls="controls"
        />
        <video
          src={tes5}
        
          controls="controls"
        />
        <video
          src={tes6}
         
          controls="controls"
        />

      </div>
   
  );
}

export default GalleryPhoto;


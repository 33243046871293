import "./referralStyle.css";
import FranchiseRefData from "./FranchiseRefData";
import Owner1 from "../assets/MR-1.jpg";
import Owner2 from "../assets/missing.png";
import Owner3 from "../assets/missing.png";

function FranchiseRef() {
  return (
    <div className="FranchiseRef">
      <h1>Referral Persons</h1>
      <p>
      Making success stories of Others..!!
      </p>
      <div className="FranchiseRefcard">
      <FranchiseRefData image={Owner1} heading="Madhuri mahesh bhosale"    />
      <FranchiseRefData image={Owner2} heading="Unkonwn"    />
      <FranchiseRefData image={Owner3} heading="Unkonwn"    />
      

      </div>
     

       
    </div>
  );
}

export default FranchiseRef;
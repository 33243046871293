

export const MenuItems = [
  {
    title: "Business",
    url: "/",
    cName: "nav-links",
    icon: "fa-sharp fa-solid fa-business-time",
    overflow: "auto",
  },
  {
    title: "Gallery",
    url: "/Gallery",
    cName: "nav-links",
    icon: "fa-solid fa-image",
    overflow: "auto",
  },
  {
    title: "About",
    url: "/About",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info",
    overflow: "auto",
  },
  {
    title: "NEET",
    url: "/Service",
    cName: "nav-links",
    icon: "fa-solid fa-book-open-reader",
    overflow: "auto",
  },
  {
    title: "Benefits",
    url: "/Importance",
    cName: "nav-links",
    icon: "fa-solid fa-lightbulb",
    overflow: "auto",
  },

  {
    title: "Awards",
    url: "/Awards",
    cName: "nav-links",
    icon: "fa-solid fa-award",
    overflow: "auto",
  },

  {
    title: "Contact",
    url: "/Contact",
    cName: "nav-links",
    icon: "fa-solid fa-address-book",
    overflow: "auto",
  },

 

  {
    title: "Business From",
    url: "https://forms.gle/1WSHkXLTN2kngxBeA",
    cName: "nav-links-mobile",
    overflow: "auto",
  },

];

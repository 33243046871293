import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import FranchiseRef from "../component/FranchiseRef";
import logo from "../assets/Owner1.jpeg";

function FranchiseUsRef() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mids1"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Abhijeet Sagwekar"
        text="ID=TRMF0106202401"
        image={logo}
      />
      <FranchiseRef />
      <Footer />
    </>
  );
}

export default FranchiseUsRef;

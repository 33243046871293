import problem1 from "../assets/h.jpeg";
import problem2 from "../assets/k.jpeg";
import problem3 from "../assets/c.jpeg";
import problem4 from "../assets/i.jpeg";
import problem5 from "../assets/f.jpeg";
import problem6 from "../assets/e.jpeg";
import "./InformationStyles.css";
import InformationData from "./InformationData";

const Information = () => {
  return (
    <div className="Information">
      <h1>
        Unlock Your Full Potential With
        <br /> Top Rank Makers
      </h1>
      <p className="centered-paragraph">Mind Power | Max Memory | Active Learning | Rank Booster</p>
      <InformationData
        className="first-des"
        heading="STUDENT PROBLEM'S"
        text={`1) NEET-JEE स्पर्धा परीक्षांचा आवाका लक्षात येत नाही ?
        2) Offline-Online Distraction मध्ये मुलं अडकतात ?
        3) ध्येय-अपेक्षा-स्वप्न खुप असतात मात्र Performance येत नाही ?
        4) सराव परीक्षा व मुख्य परीक्षेमध्ये Silly Mistakes होतात ?
        5) उत्तरे चुकतात - Negative मध्ये खुप मार्क जातात ?
        6) Revision - Proper preparation होत नाही ?
        7) थेट परीक्षे पर्यन्त महत्त्वाच्या Concept समजत नाहीत ?
        8) शारीरीक व मानसिक थकवा येतो ?
        9) नैराश्य-वैकल्य-चिंता-भिती- ताणतणाव वाढतो ?
        10) अभ्यास नको वाटून - प्रसंगी आत्महत्तेचे विचार येतात ?
        11) Physics - Chemistry चा SCORE वाढत नाही ?
        12) Timetable Follow होत नाही ?
        13) अभ्यास झालेला असला, तरी परीक्षेत तात्काळ आठवत नाही ?`}
        img1={problem1}
        img2={problem2}
      />

      <InformationData
        className="first-des-reverse"
        heading="SELFSTUDY IMPORTANCES "
        text="प्रत्येक क्लास व कॉलेज मध्ये सर्व शिक्षक उत्तम शिकवतात.
        त्यांचे योगदान महत्वपूर्ण आहे. 
         मात्र मुलांचा स्वतःचा अभ्यास परीणामकारक व परिपूर्ण असणे महत्वाचे आहे. 
      
         विद्यार्थाची TOP Rank-Top Score-Top Result
          साठी 70% यer Perfect Self Study वर अवलंबुन आहे. 
          आम्ही तुमचा Self Study Pattern Boost करून 
          तुमची अभ्यासाची Speed आणी Accuracy वाढवतो. 
          या मुळे NEET-JEE परीक्षांमध्ये Performance वाढतो. 
          याचा चमत्कारा प्रमाणे मुलांना फायदा होतो, 
          अगदी 2-5 Score वरून मुलांचे भवीष्य बदलते. "
        img1={problem6}
        img2={problem4}
      />
 
      <InformationData
        className="first-des"
        heading="प्रिय आई-बाबा... Top Score चे महत्व"
        text="NEET-JEE परीक्षांमधील Top Rank - Top Score - Top- Result विद्यार्थ्यांना सर्वोत्तम करीअर देते. 
        महाराष्ट्रातील व भारतातील Top Rank Medical, Engineering महाविद्यालयात प्रवेश मिळवून देतो. 
        कमी खर्चा मध्ये विद्यार्थीचे दर्जेदार शिक्षण होते. पालक व विद्यार्थांचा ताणतणाव कमी होतो "
        img1={problem5}
        img2={problem3}
      />

      <InformationData
        className="first-des-reverse"
        heading="One Day Full Time WorkShop "
        text="एक दिवसी य पूर्णपणे समर्पित कार्यशाळा... 
        मुलांचा वेळ वाचवून एका - दिवसामध्ये पूर्ण प्रशिक्षण मिळते 
        व मुलांचा Rank-score- Result वाढतो. 
        रवीवारी काही पालक Sunday test ला विराम देतात कारण... 
        सतत सतत कमी SCORE येणारी Test series मनाचे Distraction वाढवते ... 
        एक दिवसी य पूर्णपणे समर्पित कार्यशाळे नंतरच्या प्रत्येक Sunday Test चा Score वाढेल.
         अगदी आत्मविश्वास व खात्रीने ! तुमचे ध्येय-स्वप्न साकार होईल.
        NEET-JEE च्या Top Rank -Top score-Top Result साठी किती अग्नीदिव्यातून जावे लागते! 
         आम्हाला याची जाणीव आहे. तुमच्या मुलांचे व तुमचे स्वप्न- ध्येय पूर्ण करण्यासाठी, मुलांचा 
         अभ्यास करण्याच्या पद्धती व परीक्षेतील Performance वाढवयाचे तंत्र बदलले पाहिजे"
        img1={problem3}
        img2={problem4}
      />
    </div>
  );
};

export default Information;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Service from "./routes/Service";
import Contact from "./routes/Contact";
import Gallery from "./routes/Gallery";
import Importance from "./routes/Importance";
import FranchiseUsRef from "./routes/FranchiseUsRef";
import Awards from "./routes/Awards"
import Franchise from "./component/Franchise";
import ReferralUsRef from "./routes/ReferralUsRef";


import 'normalize.css';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  

  return (
    <div className="App">
      <Helmet>
        <title>Top Rank Makers</title>
        <meta
          name="description"
          content="Celebrate Your Grand Success..!Reserve Your....Top Rank - Top Score - Top Result"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Importance" element={<Importance />} />
        <Route path="/FranchiseUsRef" element={<FranchiseUsRef/>} />
        <Route path="/Awards" element={<Awards/>}/>
        <Route path="/Franchise" element={<Franchise/>}/>
        <Route path="/ReferralUsRef" element={<ReferralUsRef/>} />
      </Routes>
    </div>
  );
}

export default App;

import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

import logo from "../assets/awlogo.jpg";
import Awardsphoto from "../component/Awardsphoto";

function Awards() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid01"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Awards"
        text2="Are You Want to Apply...?"
        buttonText2="REGISTER NOW"
        url2="https://forms.gle/yu4cqEdvtngUfHME8"
        btnClass2="show2"
        image={logo}
      />
      <Awardsphoto />
      <Footer />
    </>
  );
}
export default Awards;

import "./AwardsphotoStyle.css";
import photo1 from "../assets/aw1.jpg";
import photo2 from "../assets/aw2.jpg";
import photo3 from "../assets/aw3.jpg";
import photo4 from "../assets/aw4.jpg";
import photo5 from "../assets/aw5.jpg";
import photo6 from "../assets/aw6.jpg";
import photo7 from "../assets/aw7.jpg";
import photo8 from "../assets/aw8.jpg";
import photo9 from "../assets/aw9.jpg";
import photo11 from "../assets/aw12.jpg";
import photo10 from "../assets/aw10.jpg";

function Awardsphoto() {
  return (
    <div className="Awards-Photo">
      <img src={photo1} alt="Award 1" />
      <img src={photo2} alt="Award 2" />
      <img src={photo11} alt="Award 11" />
      <img src={photo3} alt="Award 3" />
      <img src={photo4} alt="Award 4" />
      <img src={photo5} alt="Award 5" />
      <img src={photo6} alt="Award 6" />
      <img src={photo7} alt="Award 7" />
      <img src={photo8} alt="Award 8" />
      <img src={photo9} alt="Award 9" />
      <img src={photo10} alt="Award 10" />
      <div className="awards-text">
        <h1>
        राज्यस्तरीय पुरस्कार सोहळा <br />
        1. आदर्श शिक्षक पुरस्कार<br />
        2. आदर्श शैक्षणिक संस्था पुरस्कार</h1>
      </div>
      <a href="https://forms.gle/yu4cqEdvtngUfHME8" target="_blank" rel="noopener noreferrer" className="register-button">
        REGISTER NOW
      </a>
    </div>
  );
}

export default Awardsphoto;

import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import AboutUs from "../component/AboutUs";
import logo from "../assets/toprank.jpg";


function About() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Grow with us !!"
        image={logo}
      />

      <AboutUs />
      <Footer />
    </>
  );
}

export default About;

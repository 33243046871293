import "./AboutUsStyle.css";
import head1 from "../assets/aboutp.jpeg"
import head2 from "../assets/j.jpeg";
import head3 from "../assets/l.jpeg";
import head4 from "../assets/m.jpeg";
import head5 from "../assets/a.jpeg";


function AboutUs() {
  return (
    <div className="about-container">
      <img src={head1} />
      <h2>
        ( मा. नामदेव रामराव ) आणि ( डॉ. श्रद्धा नामदेव ) <br />
      </h2>
      <h4>
      BRAIN-MIND-MEMORY-ACTIVE LEARNING EXPERT (शासकिय वैद्यकीय महाविद्यालय,
        नागपूर) <br />
        IDEAL PARENTING COACH
      </h4>
      <h1>Celebrate Your Grand Success..!<br/>Reserve Your.... "Top Rank - Top Score - Top Result "</h1>
      <p>
      महाराष्ट्राचे अग्रणी प्रशिक्षक हजारो विद्यार्थी-पालकांचे समुपदेशक , प्रशिक्षक, 
      BRAIN-MIND-MEMORY सक्रिय शिक्षण तज्ञ मा. नामदेव रामराव मिसाळ  (NLP-AI-SUCCESS COACH)
BRAIN-MIND-MEMORY-ACTIVE LEARNING EXPERT 
|  IDEAL PARENTING COACH आणि त्यांची कन्या डॉ श्रद्धा नामदेव मिसाळ,  
(Government Medical Collage , Nagpur ) यांनी  NEET आणि JEE च्या हजारो विद्यार्थ्यांना मन-मेंदू-विचार-दृश्य-क्षमतेने मदत केली आहे. 
सवयी-संस्कृती-प्रतिभा-बुद्धीमत्तेचा प्रभावी वापर करण्यासाठी प्रशिक्षण दिले जाते यातून विद्यार्थ्यांनी टॅप रँक मिळवून यश संपादन केले आहे.
        <br />
        <br />
        <b>त्यांचा थोडक्यात परिचय :-</b>विद्यार्थी-पालक समुपदेशन-प्रशिक्षण-मेंदू-मन- स्मरणशक्ती-क्रियाशील शिक्षण तज्ञ मा. नामदेव रामराव मिसाळ 
गेल्या 23 वर्षांपासून शैक्षणिक क्षेत्रात कार्यरत आहेत.
त्यांनी शिक्षण व्यवस्थापन संस्था स्थापन करून शिक्षण क्षेत्रात आपले नाव उंचावले आणि विद्यार्थ्यांना मार्गदर्शन केले .

        <br />
        <br />
        संस्थेच्या अध्यक्षपदाची जबाबदारी पार पाडून त्यांनी महाराष्ट्र राज्यातील हजारो विद्यार्थी-पालकांची सेवा केली. यामध्ये प्रामुख्याने आदर्श विद्यार्थी आणि चांगले पालक कसे बनवायचे याचे प्रशिक्षण व मार्गदर्शन याचा समावेश होतो.
        एवढ्यावरच न थांबता त्यांनी टॉप रँक मेकर्स , लातूर नावाची संस्था स्थापन केली. या संस्थेच्या माध्यमातून NEET आणि JEE  परीक्षेची तयारी करणाऱ्या विद्यार्थ्यांसाठी स्व-अभ्यास वाढवणे आणि प्रभावी पालकत्व यांचे प्रशिक्षण देत आहेत.
        स्पर्धा परीक्षांची तयारी करणाऱ्या विद्यार्थ्यांच्या सुरक्षित आयुष्यासाठी आणि उज्ज्वल भविष्यासाठी तणावमुक्त विद्यार्थी- तणावमुक्त परीक्षा अभियान सुरू करण्यात आले आहे. 
        या अभियानांतर्गत महाराष्ट्र राज्यातील 20 हजारांहून अधिक विद्यार्थ्यांना I LOVE EXAM चे प्रशिक्षण देण्यात आले आहे.
        <br />
        <br />
        लातूरचे जिल्हाधिकारी आणि पोलीस अधीक्षक यांच्याकडून या मोहिमेला सुरुवात करण्यात आली आहे. 
        या मोहिमेतील हेल्पलाइन 7 6 2 0 4 6 4 6 8 0 ( 24 X 7) विद्यार्थ्यांसाठी उघडण्यात आली आहे. 
         विद्यार्थी जेथे असतील तेथे मार्गदर्शन केंद्रे सुरू करण्यात आली आहेत.
        विद्यार्थ्यांनी अभ्यासावर आधारित परीक्षेत काय साध्य केले पाहिजे, यात त्यांनी यश मिळवले .
जीवनातील प्रत्येक परीक्षेला न डगमगता सामोरे जाणे आणि आत्मविश्वासाने यशस्वी होणे आवश्यक असते यासाठी तज्ज्ञ मा. नामदेव रामराव मिसाळ व त्यांची कन्या डॉ.श्रद्धा नामदेव मिसाळ या काम करत आहेत.
        <br />
        <br />
        मा. नामदेव रामराव मिसाळ Brain - Mind - memory - Active Learning Expert  हे विद्यार्थी-पालकांचे समुपदेशक ,प्रशिक्षक , तसेच महाराष्ट्रात अभ्यासू म्हणून ओळखले जातात आणि लेखकही आहेत. त्यांनी 2006 मध्ये 
सुपर मास्टर गाइड (स्पर्धा परीक्षा मार्गदर्शन) 
पुस्तक लिहिले आहे. या पुस्तकाचे प्रकाशन मा. किरण बेदी यांच्या हस्ते करण्यात आले. 
त्यांचे नवीन पुस्तक मेक युवर किड्स टॉपर लवकरच प्रकाशित होत आहे.
       
      </p>
      <div className="about-photo">
        <h1>More Infromation</h1>
        <img src={head2} />
        <img src={head3} />
        <img src={head4} />
        <img src={head5} />
      </div>
    </div>
  );
}

export default AboutUs;

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactFormStyles.css';

function ThankYouPopup({ onClose }) {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>Thank you</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

function ContactForm() {
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_4annrk4', 'template_jndfvy3', form.current, {
        publicKey: 'ik1CAZVM2ZJ-N8fp9',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          console.log("message sent");
          e.target.reset();
          setShowPopup(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="from-container">
      <form ref={form} onSubmit={sendEmail}>
        <input placeholder="Name" type="text" name="Name" />
        <input placeholder="Email" type="email" name="Email" />
        <input placeholder="Subject" type="text" name="Subject"/>
        <textarea placeholder="Message" type="message" name="message"></textarea>
        <button>Submit</button>
      </form>
      {showPopup && <ThankYouPopup onClose={closePopup} />}
    </div>
  );
}

export default ContactForm;

import "./FooterStyles.css";
import React from 'react';


const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1> Top Rank Makers</h1>
          <p>
            Reserve Your... ' Top Rank - Top Score - Top Result' ( NEET & JEE)
          </p>
          <p>
            1st Floor, B2B Elite, Deshikendra School Road, Signal Camp,Latur(Maharashtra)-413512..  
          </p>
        </div>
        <div>
          <a
            href="https://www.facebook.com/naramisal2022?mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a
            href=" https://www.instagram.com/invites/contact/?i=12cpzxhj7hqyj&utm_content=slt2b73"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a
            href="https://youtube.com/@toprankmakers704?si=5neNbG75iLFi1kGv"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-youtube-square"></i>
          </a>
          <a
            href="https://twitter.com/TopRankmakers?t=NEMe8Gh8tn-rzacwQNvsAw&s=09"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>Director</h4>
          <a
            href="https://www.instagram.com/shraddhamisal5?igsh=NTc4MTIwNjQ2YQ%3D%3D"
            target="_blank"
            rel="noreferrer"
          >
            डॉ. श्रद्धा नामदेव
          </a>
          <a
            href="https://www.instagram.com/naramisal11?igsh=NTc4MTIwNjQ2YQ%3D%3D"
            target="_blank"
            rel="noreferrer"
          >
            मा. नामदेव रामराव
          </a>
        </div>
        <div>
          <h4>Community</h4>
          <a
            href="https://chat.whatsapp.com/D86qUlCoqw98S8FV5dXcJa"
            target="_blank"
            rel="noreferrer"
          >
            WhatsApp
          </a>
          <a
            href="https://www.facebook.com/naramisal2022?mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            FaceBook
          </a>
          <a
            href="https://youtube.com/@toprankmakers704?si=5neNbG75iLFi1kGv"
            target="_blank"
            rel="noreferrer"
          >
            YouTube
          </a>
          <a
            href="https://twitter.com/TopRankmakers?t=NEMe8Gh8tn-rzacwQNvsAw&s=09"
            target="_blank"
            rel="noreferrer"
          >
            twitter
          </a>
        </div>
        <div>
          <h4>Help</h4>
          <a
            href="https://chat.whatsapp.com/D86qUlCoqw98S8FV5dXcJa"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
          <a
            href="https://chat.whatsapp.com/D86qUlCoqw98S8FV5dXcJa"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
        </div>
        <div>
          <h4>Other</h4>
          <a href={`${process.env.PUBLIC_URL}/Term_Of_Service.html`} target="_blank" rel="noopener noreferrer">Term Of Service</a>
   
           
          <a href={`${process.env.PUBLIC_URL}/Privacy_Policy.html`} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href={`${process.env.PUBLIC_URL}/Refund_Policy.html`} target="_blank" rel="noopener noreferrer">Refund Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import "./HeroStyles.css";
import coloer1 from "../assets/bg2.png";



function Hero(props) {
  return (
    <>
      <div className={props.cName}>
        <img src={coloer1} />

        <div className="hero-text">
        <p>{props.text1}</p>
        <img src={props.image}/>
          <h1>{props.title}</h1>
          <h2>{props.title1}</h2>
          <p>{props.text}</p>
          <a href={props.url} className={props.btnClass}>
            {props.buttonText}
          </a>
          <p>{props.text2}</p>
          <a href={props.url2} className={props.btnClass2}>
            {props.buttonText2}
          </a>
        </div>
      </div>
    </>
  );
}

export default Hero;

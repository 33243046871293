import "./FranchiseStyle.css";
import FranchiseData from "./FranchiseData";
import Owner1 from "../assets/Owner1.jpeg";
import Owner2 from "../assets/missing.png";

function Franchise() {
  return (
    <div className="Franchise">
      <h1>Our Franchise Owner</h1>
      <p>Your Pathway to Success Starts Here</p>
      <div className="Franchisecard">
        <FranchiseData
          image={Owner1}
          heading="Abhijeet Sagwekar"
          text="ID=TRMF0106202401"
          text1="Click Here For More info"
          link="/FranchiseUsRef"
        />
         <FranchiseData
          image={Owner2}
          heading="Unkonwn"
          text="ID"
          text1="Click Here For More info"
          link="/"
        />
         <FranchiseData
          image={Owner2}
          heading="Unkonwn"
          text="ID"
          text1="Click Here For More info"
          link="/"
        />
      </div>
    </div>
  );
}

export default Franchise;

import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

import fac from "../assets/key.jpg";
import ImportanceUs from "../component/ImportanceUs";

function Importance() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="Benefits of Courses "
        image={fac}
      />
      <ImportanceUs/>
      <Footer />
    </>
  );
}

export default Importance;